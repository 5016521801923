<template>
  <!-- 系统管理 -->
  <div class="system">
    <div class="usertop">
      <p>系统管理</p>
    </div>
    <div class="usermain">
      <div class="modifmess">
        <div class="usename">
          <p>
            账号：<span>{{ name }}</span>
          </p>
        </div>
        <div class="usename">
          <p>密码：<span>********</span></p>
          <p @click="xiupass">修改</p>
        </div>
        <div class="usename">
          <p>身份：超级管理员</p>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible1"
      width="600px"
      :close-on-click-modal="false"
    >
      <p>请输入原密码</p>
      <el-input
        v-model="oldpassword"
        placeholder="请输入原密码"
        style="font-size: 20px"
        type="password"
      ></el-input>
      <p>请输入新密码</p>
      <el-input
        v-model="newpassword"
        placeholder="请输入新密码"
        style="font-size: 20px"
        type="password"
      ></el-input>
      <p>验证新密码</p>
      <el-input
        v-model="newpassword2"
        placeholder="验证新密码"
        style="font-size: 20px"
        type="password"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="savepass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { adminupdate, adminindex } from "../../api/system/update.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      name: "",
      password: "",
      dialogVisible: false,
      dialogVisible1: false,
      username: "",
      newpassword: "",
      newpassword2: "",
      oldpassword: "",
    };
  },
  created() {
    adminindex().then((res) => {
      // console.log(res);
      this.detail = res.data[0];
      this.name = res.data[0].account;
    });
    this.password = this.$store.state.password;
    console.log(this.$store.state.password);
  },
  mounted() {},
  methods: {
    savepass() {
      console.log(123)
      if (this.oldpassword !== this.$store.state.password) {
        this.$message.error("旧密码验证失败！");
      } else {
        if (this.newpassword === this.newpassword2) {
          adminupdate(
            "1",
            "",
            "",
            this.name,
            this.newpassword,
            "",
            "",
            "",
            ""
          ).then((res) => {
            if (res.msg == "修改成功！") {
              this.$store.commit("savepassword", this.newpassword);
              this.password = this.newpassword;
              this.$message({
                message: "修改密码成功！",
                type: "success",
              });
              this.dialogVisible1 = false;
            } else {
              if (res.data) {
                this.$message.error(res.data.password[0]);
              } else {
                this.$message.error("密码与之前相同！");
              }
            }
          });
        } else {
          this.$message.error("新密码验证失败，请重新输入！");
        }
      }
    },
    // 修改账号
    xiuname() {
      this.username = "";
      this.dialogVisible = true;
    },
    xiupass() {
      this.newpassword = "";
      this.newpassword2 = "";
      this.oldpassword = "";
      this.dialogVisible1 = true;
    },
  },
};
</script>

<style  lang='less' scoped>
.system {
  padding: 20px;
  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    font-size: 24px;
    padding-bottom: 15px;
  }
  /deep/.el-dialog__body {
    padding-top: 0px;
    p {
      font-size: 22px;
    }
    /deep/.el-input__inner {
      width: 60%;
    }
  }
  .usename {
    width: 240px;
    display: flex;
    justify-content: space-between;
    p:nth-of-type(2) {
      color: #4483f4;
      font-size: 24px;
      cursor: pointer;
    }
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
}
</style>



